class Modal {
  constructor(element) {
    this.element = element;
    this.initBindings();
  }

  initBindings() {
    const modalSelector = this.element.getAttribute('data-target');
    if (!modalSelector) {
      return;
    }

    const toggleModalCb = this.toggleModal.bind(this);

    this.modalElement = document.querySelector(modalSelector);
    this.element.addEventListener('click', toggleModalCb);

    const closeButtons = this.modalElement.querySelectorAll('[data-dismiss="modal"]');
    Array.prototype.forEach.call(closeButtons, (btn) =>
      btn.addEventListener('click', toggleModalCb),
    );
  }

  toggleModal(e) {
    e.preventDefault();
    this.modalElement.classList.toggle('modal--open');
  }
}

export default Modal;

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('[data-kjb-element="modal-trigger"]');
  if (!elements) {
    return;
  }

  Array.prototype.forEach.call(elements, (element) => new Modal(element));
});
