import copy from 'copy-to-clipboard';

const UPDATE_NOTIFICATION_TIMER = 3500;
class ClipboardButton {
  constructor(element) {
    this.element = element;
    this.element.addEventListener('click', (evt) => {
      evt.preventDefault();
      const copySuccess = copy(this.element.href);

      if (copySuccess) {
        this.showNotification();
      }
    });
  }

  showNotification() {
    if (document.querySelector('[data-kjb-clipboard-button="notification"]') != null) {
      return;
    }
    this.element.after(this.notificationEl);
    window.setTimeout(this.removeNotification.bind(this), UPDATE_NOTIFICATION_TIMER);
  }

  removeNotification() {
    const notification = document.querySelector('[data-kjb-clipboard-button="notification"]');
    notification.parentNode.removeChild(notification);
  }

  get notificationEl() {
    const span = document.createElement('span');
    span.classList.add('podcast-intro__rss');
    span.innerText = 'Copied to clipboard!';
    span.dataset.kjbClipboardButton = 'notification';
    return span;
  }
}

export default ClipboardButton;

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('[data-kjb-element="clipboard-button"]');
  if (!elements) {
    return;
  }

  Array.prototype.forEach.call(elements, (element) => new ClipboardButton(element));
});
